import PropTypes from 'prop-types'
import React from 'react'
import Layout from '../components/ui-components/containers/layout'
import TextContainer from '../components/ui-components/containers/text-container'

const Danke = ({}) => {
  return (
    <Layout>
      <TextContainer>
        <div className="text-center">
          <h2 className="font-handwritten text-5xl mt-24">
            Vielen Dank für deine Nachricht!
          </h2>
          <h3 className="text-3xl mt-4">
            Wir antworten so schnell wie möglich.
          </h3>
        </div>
      </TextContainer>
    </Layout>
  )
}

Danke.propTypes = {}

export default Danke
